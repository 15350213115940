import logo from './logo.svg';
import './App.css';
import React from "react";
import {SidebarComponent} from "./components/Sidebar/SidebarComponent"
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div className="App">
        <SidebarComponent />
        
    </div>
  );
}

export default App;

